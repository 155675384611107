<template>
  <div>
    <vx-card class="mt-8">
      <div class="card-title mb-2">
        <h2>Map Billing Components</h2>
      </div>
      <div class="map-bill">
        <form @submit.prevent="checkValidation()" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-full mb-8">
                  <h3>Anonymous Visitor Identification</h3>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Anonymous Visitor</span>
                  <v-select
                    v-model="anonymousVisitor"
                    :options="anonymousVisitoroptions"
                    v-validate="'required'"
                    name="anonymousVisitor"
                  />
                  <span class="text-danger error-msg">{{ errors.first('anonymousVisitor') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>User Accounts</span>
                  <v-select
                    v-model="userAccounts"
                    :options="userAccountsoptions"
                    v-validate="'required'"
                    name="userAccounts"
                  />
                  <span class="text-danger error-msg">{{ errors.first('userAccounts') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Page View Overage</span>
                  <v-select
                    v-model="pageView"
                    :options="pageVieworoptions"
                    v-validate="'required'"
                    name="pageView"
                  />
                  <span class="text-danger error-msg">{{ errors.first('pageView') }}</span>
                </div>
              </div>
            </div>
            <div class="vx-col w-full mt-6">
              <div class="vx-row">
                <div class="vx-col w-full mb-8">
                  <h3>Call Tracking</h3>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Local Number</span>
                  <v-select
                    v-model="localNumber"
                    :options="localNumberoptions"
                    v-validate="'required'"
                    name="localNumber"
                  />
                  <span class="text-danger error-msg">{{ errors.first('localNumber') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Toll Free Number</span>
                  <v-select
                    v-model="tollFreeNumber"
                    :options="tollFreeNumberoptions"
                    v-validate="'required'"
                    name="tollFreeNumber"
                  />
                  <span class="text-danger error-msg">{{ errors.first('tollFreeNumber') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Local Minutes</span>
                  <v-select
                    v-model="localMinutes"
                    :options="localMinutesoptions"
                    v-validate="'required'"
                    name="localMinutes"
                  />
                  <span class="text-danger error-msg">{{ errors.first('localMinutes') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Toll Free Minutes</span>
                  <v-select
                    v-model="tollFreeMinutes"
                    :options="tollFreeMinutesoptions"
                    v-validate="'required'"
                    name="tollFreeMinutes"
                  />
                  <span class="text-danger error-msg">{{ errors.first('tollFreeMinutes') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Text Messages</span>
                  <v-select
                    v-model="textMessages"
                    :options="textMessagesoptions"
                    v-validate="'required'"
                    name="textMessages"
                  />
                  <span class="text-danger error-msg">{{ errors.first('textMessages') }}</span>
                </div>
                <div class="vx-col lg:w-1/3 w-full mb-6">
                  <span>Trascription Minutes</span>
                  <v-select
                    v-model="trascriptionMin"
                    :options="trascriptionMinoptions"
                    v-validate="'required'"
                    name="trascriptionMin"
                  />
                  <span class="text-danger error-msg">{{ errors.first('trascriptionMin') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row justify-end">
            <div class="vx-col mt-4 sm:w-auto w-full">
              <vs-button
                button="submit"
                color="primary"
                type="filled"
                class="mr-4 small-btn mb-2 sm:mb-0 sm:w-auto w-full"
              >{{ LabelConstant.buttonLabelUpdateResellerComponents }}</vs-button>
              <vs-button
                color="grey"
                type="border"
                class="mb-0 small-btn sm:w-auto w-full grey-btn"
                @click="cancelMychanges()"
              >{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
            </div>
          </div>
        </form>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    anonymousVisitor: {
      required: "Anonymous Visitor selection is required"
    },
    userAccounts: {
      required: "User account selection is required"
    },
    pageView: {
      required: "Page View Overage selection is required"
    },
    localNumber: {
      required: "Local Number selection is required"
    },
    tollFreeNumber: {
      required: "Toll Free Number selection is required"
    },
    localMinutes: {
      required: "Local Minutes selection is required"
    },
    tollFreeMinutes: {
      required: "Toll Free Minutes selection is required"
    },
    textMessages: {
      required: "Text Messages selection is required"
    },
    trascriptionMin: {
      required: "Transcription selection is required"
    }
  }
};
Validator.localize("en", dict);
export default {
  components: {
    VxCard,
    vSelect
  },
  data() {
    return {
      anonymousVisitor: null,
      anonymousVisitoroptions: [
        { label: "Resold Account Basic......... $25.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      userAccounts: null,
      userAccountsoptions: [
        { label: "Additional User - Discounted 2.5 ......... $25.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      pageView: null,
      pageVieworoptions: [
        { label: "Additional Page View - 5000 ......... $5.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      localNumber: null,
      localNumberoptions: [
        { label: "Call Tracking Number - Local - Reseller ......... $0.85" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      tollFreeNumber: null,
      tollFreeNumberoptions: [
        { label: "Call Tracking Number - Toll Tree - Number ......... $2.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      localMinutes: null,
      localMinutesoptions: [
        { label: "Call Tracking Number - Local - Minutes ......... $2.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      tollFreeMinutes: null,
      tollFreeMinutesoptions: [
        { label: "Call Tracking Toll - Free - Minutes ......... $2.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      textMessages: null,
      textMessagesoptions: [
        { label: "Call Tracking Text - Message ......... $2.00" },
        { label: "Resold Account Discount......... $25.00" }
      ],
      trascriptionMin: null,
      trascriptionMinoptions: [
        { label: "Call Tracking Trascription - Miniutes ......... $2.00" },
        { label: "Resold Account Discount......... $25.00" }
      ]
    };
  },
  created() {
    this.getBillingMap();
  },
  methods: {
    async checkValidation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          //this.createDomain();
        }
      });
    },
    async getBillingMap() {
      this.$vs.loading();
      await this.axios
        .get("/ws/ResellerBillingMap/GetBillingResellerComponentMap")
        .then(response => {
          let data = response.data
          this.anonymousVisitor = data.Anonymous_Visitor_Account;
          this.userAccounts = data.User_Account;
          this.pageView = data.Page_View_Overage;
          this.localNumber = data.Local_Number;
          this.tollFreeNumber = data.Toll_Free_Number;
          this.localMinutes = data.Local_Minutes;
          this.tollFreeMinutes = data.Toll_Free_Minutes;
          this.textMessages = data.Text_Messages;
          this.trascriptionMin = data.Transcription_Minutes;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async updateBillingMap() {
      this.$vs.loading();
      let input = {
        Anonymous_Visitor_Account: this.anonymousVisitor,
        User_Account: this.userAccounts,
        Page_View_Overage: this.pageView,
        Local_Number: this.localNumber,
        Toll_Free_Number: this.tollFreeNumber,
        Local_Minutes: this.localMinutes,
        Toll_Free_Minutes: this.tollFreeMinutes,
        Text_Messages: this.textMessages,
        Transcription_Minutes: this.trascriptionMin,
      };
      await this.axios
        .post("/ws/ResellerBillingMap/UpdateBillingResellerComponentMap", input)
        .then(() => {
          this.getBillingMap();

          this.$vs.loading.close();
            this.$vs.notify({
            title: "Success",
            text: "Your information has been updated successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelMychanges(){
        this.getBillingMap();
         this.$vs.notify({
            title: "Changes Reverted",
            text: "Your information has been restored to the original state.",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-triangle",
            position: "top-right",
            time: 4000
          });
    }
  }
};
</script>
